import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';
import { english } from 'flatpickr/dist/l10n/default.js';
import { Estonian } from 'flatpickr/dist/l10n/et.js';
import { Russian } from 'flatpickr/dist/l10n/ru.js';

export default class extends Controller {
    connect() {
        const minDate = this.element.dataset.minDate || 'today';
        const maxDate = this.element.dataset.maxDate || null;

        flatpickr(this.element, {
            minDate: minDate,
            maxDate: maxDate,
            locale: this.getLocale(),
            dateFormat: 'Y-m-d',
            disableMobile: true,
        });
    }

    getLocale() {
        switch (document.documentElement.lang.split('-')[0]) {
            case 'ee':
                return Estonian;
            case 'en':
                return english;
            case 'ru':
                return Russian;
            default:
                return Estonian;
        }
    }
}
