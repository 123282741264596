import { Controller } from '@hotwired/stimulus';
import queryString from 'query-string';

export default class extends Controller {
	static get targets () {
		return ['form', 'container', 'pagination', 'clear', 'indicator', 'ttNext', 'ttPrev'];
	}

	connect() {
		this.template = this.element.dataset.template;
		this.defaultParams = JSON.parse(this.element.dataset.defaults) || {};
		this.params = this.element.dataset.defaults !== '[]' ? this.defaultParams : {};
		this.currentLanguageCode = document.documentElement.lang.split('-')[0];
		this._debounce = null;
	}

	formTargetConnected(form) {
		const forceSubmitElements = form.querySelectorAll('[data-force-submit="1"]');

		if (forceSubmitElements.length) {
			forceSubmitElements.forEach((el) => {
				if (['SELECT', 'SELECT-ONE', 'SELECT-MULTIPLE'].includes(el.tagName)) {
					if (el.multiple) {
						el.addEventListener('change', () => {
							clearTimeout(this._debounce);

							this._debounce = setTimeout(async () => {
								await this.loadTemplate(form);
							}, 1000);
						});
					} else {
						el.addEventListener('change', async () => {
							await this.loadTemplate(form);
						});
					}
				}
			});
		}

		form.addEventListener('submit', async (e) => {
			e.preventDefault();

			if (e.submitter && e.submitter.getAttribute('value')) {
				this.defaultParams[e.submitter.getAttribute('name')] = e.submitter.value;
			}

			if (this.hasPaginationTarget) {
				this.defaultParams.js_p = 1;
			}

			await this.loadTemplate(form);
		});

		form.querySelectorAll('.clear').forEach((clear) => {
			clear.addEventListener('click', async () => {
				let formEl = form;
				if (clear.dataset.target) {
					formEl = document.querySelector(clear.dataset.target);
				}

				this.clearForm(formEl);
				if (this.hasPaginationTarget) {
					this.defaultParams.js_p = 1;
				}

				this.params = {};
				await this.loadTemplate(formEl);
			});
		});
	}

	paginationTargetConnected(element) {
		const prevBtn = element.querySelector('.pagination__nav--prev');
		const nextBtn = element.querySelector('.pagination__nav--next');
		const totalPages = parseInt(element.dataset.total);
		const select = element.querySelector('select');

		select.addEventListener('change', async (e) => {
			const val = parseInt(e.target.value);
			prevBtn.classList[val === 1 ? 'add' : 'remove']('d-none');
			nextBtn.classList[val === totalPages ? 'add' : 'remove']('d-none');
			this.params.js_p = val;
			await this.loadTemplate();
		});

		prevBtn.addEventListener('click', () => {
			select.value = parseInt(select.value) - 1;
			select.dispatchEvent(new Event('change'));
		});

		nextBtn.addEventListener('click', () => {
			select.value = parseInt(select.value) + 1;
			select.dispatchEvent(new Event('change'));
		});
	}

	ttNextTargetConnected(element) {
		element.addEventListener('click', () => {
			let form;
			if (element.dataset.form) {
				form = document.querySelector(element.dataset.form);
			} else {
				form = this.formTarget;
			}

			if (form.classList.contains('loading')) {
				return;
			}

			this.setWeek(element, form, 7);
		});
	}

	ttPrevTargetConnected(element) {
		element.addEventListener('click', () => {
			let form;
			if (element.dataset.form) {
				form = document.querySelector(element.dataset.form);
			} else {
				form = this.formTarget;
			}

			if (form.classList.contains('loading')) {
				return;
			}

			this.setWeek(element, form, -7);
		});
	}

	async loadTemplate(form = null) {
		if (this.element.classList.contains('loading')) {
			return;
		}

		let baseUrl = window.location.href.split('?')[0];
		baseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;

		if (form) {
			form.classList.add('loading');
			let object = {};

			new FormData(form).forEach((value, key) => {
				if (!object[key] && key.includes('[')) {
					object[key] = [];
				}

				if (!key.includes('[')) {
					object[key] = value;
				} else {
					object[key].push(value);
				}
			});

			this.params = {...object, ...this.defaultParams};
		}

		if (this.params.sch_date) {
			delete this.params.sch_start_date;
		}

		this.element.classList.add('loading');
		this.containerTarget.classList.add('loading-block');

		let d = JSON.stringify(this.params);
		window.history.replaceState({}, document.title, baseUrl + '/?' + queryString.stringify(this.params));

		fetch(`/wp-json/api/template-render?data=${d}&template=${this.template}&rest_language=${this.currentLanguageCode}`)
				.then(response => response.json())
				.then(response => {
					if (form) {
						form.classList.remove('loading');
						document.body.classList.remove('filters--opened');
					}
					this.element.classList.remove('loading');

					if (response.success) {
						this.updateIndicators();
						this.containerTarget.innerHTML = response.data.template.trim();
						this.containerTarget.classList.remove('loading-block');
						this.scrollToContainer();
					}
				})
				.catch(() => {
					this.updateIndicators();
					this.containerTarget.classList.remove('loading-block');
					this.element.classList.remove('loading');
					if (form) {
						form.classList.remove('loading');
						document.body.classList.remove('filters--opened');
					}
				});
	}

	scrollToContainer() {
		const y = this.containerTarget.getBoundingClientRect().top + window.pageYOffset -120;
		const doc = document.documentElement;
		const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

		if (y < top) {
			window.scrollTo({
				top: y
			});
		}
	}

	updateIndicators() {
		if (this.hasIndicatorTarget) {
			this.indicatorTargets.forEach((indicator) => {
				const ignore = JSON.parse(indicator.dataset.ignore || '[]');
				let count = 0;

				for (let [key, value] of Object.entries(this.params)) {
					if (ignore.includes(key)) continue;
					if (typeof value === 'object') {
						count += value.length;
					} else {
						count++;
					}
				}

				indicator.classList.remove('no-bg');
				indicator.innerHTML = count;
			});
		}
	}

	clearForm(form) {
		form.querySelectorAll('[name]').forEach((input) => {
			switch (input.type) {
				case 'text':
				case 'textarea':
					input.value = '';
					break;
				case 'checkbox':
				case 'radio':
					input.checked = false;
					break;
				case 'select':
					input.value = 'all';
					break;
				case 'select-one':
					input.tomselect.clear();
					break;
				case 'select-multiple':
					input.tomselect.clear();
					break;
			}
		});
	}

	getQueryParams(url = null) {
		let queryString = url ? url.split('?')[1] : window.location.search.slice(1);
		let obj = {};

		if (queryString) {
			queryString = queryString.split('#')[0];
			let arr = queryString.split('&');

			for (let i = 0; i < arr.length; i++) {
				let a = arr[i].split('=');

				let paramName = a[0];
				let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];

				paramName = paramName.toLowerCase();
				if (typeof paramValue === 'string') paramValue = paramValue.toLowerCase();

				paramName = decodeURIComponent(paramName);
				if (paramName.match(/\[\]$/)) {

					let key = paramName;
					if (!obj[key]) obj[key] = [];

					if (paramName.match(/\[\d+\]$/)) {
						let index = /\[(\d+)\]/.exec(paramName)[1];
						obj[key][index] = paramValue;
					} else {
						obj[key].push(paramValue);
					}
				} else {
					if (!obj[paramName]) {
						obj[paramName] = paramValue;
					} else if (obj[paramName] && typeof obj[paramName] === 'string'){
						obj[paramName] = [obj[paramName]];
						obj[paramName].push(paramValue);
					} else {
						obj[paramName].push(paramValue);
					}
				}
			}
		}

		return obj;
	}

	setWeek(element, form, days) {
		const input = form.querySelector('input[name=sch_start_date]');
		const day = input.value;
		let date = new Date(day);
		date.setDate(date.getDate() + days);

		if (typeof element.dataset.week != 'undefined') {
			let startDate = new Date(date.getFullYear(), 0, 1);
			let days = Math.floor((date - startDate) /
					(24 * 60 * 60 * 1000));
			let weekNumber = Math.ceil(days / 7);
			document.querySelector('select[data-force-submit="1"] + .ts-wrapper #tomselect-5-ts-control > div[data-ts-item]').innerHTML = element.dataset.week.replace('%s', weekNumber);
		}

		date = date.toISOString().slice(0, 10);
		input.value = date;
		this.params = this.getQueryParams();
		this.params.sch_start_date = date;
		delete this.params.sch_date;
		this.loadTemplate(form);
	}
}
