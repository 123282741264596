import { Controller } from '@hotwired/stimulus';
import Axios from '../axios';

export default class extends Controller {
	static get targets () {
		return ['controlsWeeks', 'prev', 'next', 'modal', 'class', 'startDate', 'dateChangeSelect'];
	}

	connect() {
		this._debounce = null;
	}

	classTargetConnected(element) {
		element.addEventListener('click', () => {
			this.loadClass(element.dataset.route);
		});
	}

	dateChangeSelectTargetConnected(element) {
		element.addEventListener('change', () => {
			//this.mobileFormTarget.submit();
		});
	}

	prevTargetConnected() {
		this.prevTargets.forEach(prev => {
			prev.addEventListener('click', () => {
				this.formTarget.querySelector('input[name=sch_start_date]').value = prev.dataset.value;
				this.formTarget.submit();
			});
		});
	}

	nextTargetConnected() {
		this.nextTargets.forEach(next => {
			next.addEventListener('click', () => {
				this.formTarget.querySelector('input[name=sch_start_date]').value = next.dataset.value;
				this.formTarget.submit();
			});
		});
	}

	async loadClass(route) {
		this.modalTarget.classList.add('loading');
		let response = await Axios.get(route);
		response = response.data.data;


		let tagsHtml = '';

		if (response.class.type) {
			const vars = `--class-color: ${response.class.type.color}; --class-bg-color: ${response.class.type.bg_color}; --class-border-color: ${response.class.type.border_color}`;
			tagsHtml += `<div class="timetable-tag timetable-tag--colored" style="${vars}">${response.class.type.name}</div>`;
		}

		tagsHtml += `<div class="timetable-tag timetable-tag__time">${response.class.duration} min</div>`;
		this.modalTarget.querySelector('.class-modal--tags').innerHTML = tagsHtml;

		this.modalTarget.querySelector('.class-modal--title').innerHTML = response.class.name;
		this.modalTarget.querySelector('.class-modal--date').innerHTML = response.class.date;
		this.modalTarget.querySelector('.class-modal--club').innerHTML = response.class.club.name;
		this.modalTarget.querySelector('.class-modal--instructor').innerHTML = response.class.instructor;

		if (response.class.availableSlots < response.class.attendeesLimit) {
			this.modalTarget.querySelector('.class-modal--slots').innerHTML = `<div class="class-slots d-flex align-items-center justify-content-end">
																									<div class="class-slots__progress-bar mb-0">
																										<div class="class-slots__progress ${response.class.slotsClass}" style="width: ${response.class.slotsPercentage}%"></div>
																									</div>
                                                  <div class="class-slots__text">
                                                    <span class="class-slots__text--free">${response.class.availableSlots}</span><span class="class-slots__text--total">/${response.class.attendeesLimit} </span>
                                                   </div>
                                                </div>`;
		} else {
			this.modalTarget.querySelector('.class-modal--slots').innerHTML = `<div class="class-slots d-flex align-items-center justify-content-end">
																									<div class="class-slots__progress-bar mb-0">
																										<div class="class-slots__progress ${response.class.slotsClass}" style="width: ${response.class.slotsPercentage}%"></div>
																									</div>
                                                  <div class="class-slots__text">
                                                    ${response.class.notAvailableForBooking}
                                                   </div>
                                                </div>`;
		}

		if (response.class.image) {
			this.modalTarget.querySelector('.modal-header__image').style.backgroundImage = `url(${response.class.image})`;
		} else {
			this.modalTarget.querySelector('.modal-header__image').style.backgroundImage = 'none';
		}

		const moreInfoBtn = this.modalTarget.querySelector('.more-info');
		if (response.class.url) {
			moreInfoBtn.classList.remove('d-none');
			moreInfoBtn.setAttribute('href', response.class.url);
		} else {
			moreInfoBtn.classList.add('d-none');
			moreInfoBtn.setAttribute('href', '#');
		}

		this.modalTarget.classList.remove('loading');
	}
}
