import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
	static get targets() {
		return ['days', 'hours', 'minutes', 'seconds'];
	}

	connect() {
		if (!this.element.dataset.date) {
			return;
		}

		const newDate = new Date(this.element.dataset.date).getTime();
		const timer = setInterval(() => {
			const date = new Date().getTime();
			const diff = newDate - date;

			const second = 1000;
			const minute = second * 60;
			const hour = minute * 60;
			const day = hour * 24;

			const days = Math.floor(diff / (day));
			const hours =  Math.floor((diff % (day)) / (hour));
			const minutes = Math.floor((diff % (hour)) / (minute));
			const seconds = Math.floor((diff % (minute)) / second);

			if (this.hasDaysTarget) {
				this.daysTarget.innerHTML = days < 10 ? '0' + days : days;
			}

			if (this.hasHoursTarget) {
				this.hoursTarget.innerHTML = hours < 10 ? '0' + hours : hours;
			}

			if (this.hasMinutesTarget) {
				this.minutesTarget.innerHTML = minutes < 10 ? '0' + minutes : minutes;
			}

			if (this.hasSecondsTarget) {
				this.secondsTarget.innerHTML = seconds < 10 ? '0' + seconds : seconds;
			}

			if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
				clearInterval(timer);
			}
		}, 1000);
	}
}
